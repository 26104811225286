@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Noto Sans KR", sans-serif;
  }

  body {
    @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100;
  }
}

@layer utilities {
  .bg-grid-white {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  }

  .bg-grid-8 {
    background-size: 2rem 2rem;
  }

  .responsive-container {
    @apply px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl;
  }

  .mobile-menu {
    @apply fixed inset-0 z-50 lg:hidden bg-white dark:bg-gray-900;
  }
}
